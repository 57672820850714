// // Sidebar.js
// import React, { useState } from 'react';
// import { 
//   BarChart2, 
//   Users, 
//   ShoppingBag, 
//   DollarSign, 
//   ChevronLeft, 
//   Menu, 
//   Activity, 
//   Settings, 
//   Layout, 
//   Calendar, 
//   FileText, 
//   X 
// } from 'lucide-react';
// import { Link } from 'react-router-dom';
// import './Sidebar.css'; // Include your CSS file here

// const Sidebar = () => {
//   const [isCollapsed, setIsCollapsed] = useState(false);
//   const [activeLink, setActiveLink] = useState('dashboard');

//   const menuItems = [
//     {
//       category: "Main",
//       items: [
//         { icon: Layout, label: 'Dashboard', id: 'dashboard', path: '/dashboard' },
//         { icon: BarChart2, label: 'Manage Product', id: 'product', path: '/pc' },
//         { icon: ShoppingBag, label: 'Manage Product details', id: 'products-details', path: '/create-product-details' },
//         { icon: Users, label: 'Customers', id: 'customers', path: '/contact-manage' },
//         { icon: ShoppingBag, label: 'Manage Brochure', id: 'Brochure-details', path: '/brochure' },
//         { icon: ShoppingBag, label: 'Manage Gallery', id: 'products-gallery', path: '/managegallery' },
//       ]
//     },
//     {
//       category: "Applications",
//       items: [
//         { icon: Calendar, label: 'Calendar', id: 'calendar', path: '/vc' },
//         { icon: FileText, label: 'Documents', id: 'documents', path: '/documents' },
//       ]
//     },
//     {
//       category: "Settings",
//       items: [
//         { icon: Settings, label: 'Settings', id: 'settings', path: '/settings' },
//       ]
//     }
//   ];

//   return (
//     <aside className={`technoflex-sidebar ${isCollapsed ? 'collapsed' : ''}`}>
//       <div className="sidebar-header">
//         <span className="logo"></span>
//         <button className="collapse-btn" onClick={() => setIsCollapsed(!isCollapsed)}>
//           {isCollapsed ? <X className="chevron-icon" /> : <ChevronLeft className={`chevron-icon ${isCollapsed ? 'rotate' : ''}`} />}
//         </button>
//       </div>

//       <nav className="sidebar-nav">
//         {menuItems.map((category, index) => (
//           <div key={index} className="nav-category">
//             {!isCollapsed && <h3 className="category-title">{category.category}</h3>}
//             {category.items.map((item) => (
//               <Link 
//                 key={item.id} 
//                 to={item.path} 
//                 className={`nav-item ${activeLink === item.id ? 'active' : ''}`} 
//                 onClick={() => setActiveLink(item.id)}
//               >
//                 <div className="nav-item-icon">
//                   <item.icon className="nav-icon" />
//                 </div>
//                 {!isCollapsed && (
//                   <div className="nav-item-content">
//                     <span className="nav-label">{item.label}</span>
//                   </div>
//                 )}
//               </Link>
//             ))}
//           </div>
//         ))}
//       </nav>
//     </aside>
//   );
// };

// export default Sidebar;


// Sidebar.js
// import React, { useState } from 'react';
// import { 
//   BarChart2, 
//   Users, 
//   ShoppingBag, 
//   DollarSign, 
//   ChevronLeft, 
//   Menu, 
//   Activity, 
//   Settings, 
//   Layout, 
//   Calendar, 
//   FileText, 
//   X 
// } from 'lucide-react';
// import { Link } from 'react-router-dom';
// import './Sidebar.css';
// import {  EnvironmentOutlined, GlobalOutlined } from '@ant-design/icons';
// const Sidebar = () => {
//   const [isCollapsed, setIsCollapsed] = useState(false);
//   const [activeLink, setActiveLink] = useState('dashboard');

//   const menuItems = [
//     {
//       category: "Main",
//       items: [
//         { icon: Layout, label: 'Dashboard', id: 'dashboard', path: '/dashboard' },
//         { icon: BarChart2, label: 'Manage Product', id: 'product', path: '/pc' },
//         { icon: ShoppingBag, label: 'Manage Product Details', id: 'products-details', path: '/create-product-details' },
//         { icon: Users, label: 'Customers', id: 'customers', path: '/contact-manage' },
//         { icon: ShoppingBag, label: 'Manage Brochure', id: 'brochure-details', path: '/brochure' },
//         { icon: ShoppingBag, label: 'Manage Gallery', id: 'products-gallery', path: '/productgallery' },
//         { icon: ShoppingBag, label: 'Manage Product Accessory', id: 'product-accessory', path: '/accessories' },
//         { icon: ShoppingBag, label: 'Manage Product Thumbnail', id: 'product-thumbnail', path: '/thumbnails' },
//         { icon: ShoppingBag, label: 'Manage Product Variant', id: 'product-variant', path: '/product-variants' },
//         { icon: ShoppingBag, label: 'Manage Product Table', id: 'product-table', path: '/product-table' },
//         { icon: ShoppingBag, label: 'Manage Accessories', id: 'accessories', path: '/manage-accessories' },
//         { icon: ShoppingBag, label: 'Manage Accessory Details', id: 'accessory-details', path: '/accessories-details' },
//         { icon: ShoppingBag, label: 'Accessory Thumbnail', id: 'accessory-thumbnail', path: '/accessory-thumbnails' },
//         { icon: ShoppingBag, label: 'Accessory Table', id: 'accessory-table', path: '/accessory-sizes' },
//         { icon: ShoppingBag, label: 'Manage Banner', id: 'banner', path: '/bannermanagement' },
//         { icon: ShoppingBag, label: 'Manage Testimonial', id: 'testimonial', path: '/create-testimonials' },
//         { icon: ShoppingBag, label: 'Manage Users', id: 'users', path: '/users' },
//         { icon: EnvironmentOutlined, label: 'City Management', id: 'city', path: '/cities' },
//         { icon: GlobalOutlined, label: 'Country Management', id: 'country', path: '/countries' },
//       ]
//     },
//     {
//       category: "Applications",
//       items: [
//         { icon: Calendar, label: 'Calendar', id: 'calendar', path: '/celender' },
//         { icon: FileText, label: 'Documents', id: 'documents', path: '/documents' },
//       ]
//     },
//     {
//       category: "Settings",
//       items: [
//         { icon: Settings, label: 'Settings', id: 'settings', path: '/settings' },
//       ]
//     }
//   ];

//   return (
//     <aside className={`technoflex-sidebar ${isCollapsed ? 'collapsed' : ''}`}>
//       <div className="sidebar-header">
//         <span className="logo"></span>
//         <button className="collapse-btn" onClick={() => setIsCollapsed(!isCollapsed)}>
//           {isCollapsed ? <X className="chevron-icon" /> : <ChevronLeft className={`chevron-icon ${isCollapsed ? 'rotate' : ''}`} />}
//         </button>
//       </div>

//       <nav className="sidebar-nav">
//         {menuItems.map((category, index) => (
//           <div key={index} className="nav-category">
//             {!isCollapsed && <h3 className="category-title">{category.category}</h3>}
//             {category.items.map((item) => (
//               <Link 
//                 key={item.id} 
//                 to={item.path} 
//                 className={`nav-item ${activeLink === item.id ? 'active' : ''}`} 
//                 onClick={() => setActiveLink(item.id)}
//               >
//                 <div className="nav-item-icon">
//                   <item.icon className="nav-icon" />
//                 </div>
//                 {!isCollapsed && (
//                   <div className="nav-item-content">
//                     <span className="nav-label">{item.label}</span>
//                   </div>
//                 )}
//               </Link>
//             ))}
//           </div>
//         ))}
//       </nav>
//     </aside>
//   );
// };

// export default Sidebar;

import React, { useState, useEffect } from 'react';
import { 
  BarChart2, 
  Users, 
  ShoppingBag, 
  Settings, 
  Layout, 
  Calendar, 
  FileText, 
  ChevronLeft,
  ChevronRight,
  X,
  ChevronDown,
  Activity,
  Box,
  Image,
  Layers,
  Table,
  FileImage,
  BookOpen,
  ImagePlus,
  MessageSquare,
  Globe,
  MapPin
} from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.css';
const MenuItem = ({ item, isCollapsed, activeLink, setActiveLink, level = 0 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  
  useEffect(() => {
    // Check if current path matches any submenu item
    if (item.submenu) {
      const isActiveParent = item.submenu.some(subItem => 
        location.pathname === subItem.path
      );
      setIsOpen(isActiveParent);
    }
  }, [location, item.submenu]);

  const handleItemClick = (e) => {
    if (item.submenu) {
      e.preventDefault();
      setIsOpen(!isOpen);
    } else {
      setActiveLink(item.id);
    }
  };
  
  if (item.submenu) {
    return (
      <div className="submenu-container">
        <div 
          className={`nav-item ${activeLink === item.id ? 'active' : ''}`}
          onClick={handleItemClick}
          style={{ paddingLeft: `${level * 12 + 16}px` }}
        >
          <div className="nav-item-icon">
            <item.icon className="nav-icon" />
          </div>
          {!isCollapsed && (
            <div className="nav-item-content">
              <span className="nav-label">{item.label}</span>
              <ChevronDown 
                className={`submenu-arrow ${isOpen ? 'rotate' : ''}`} 
                size={16} 
              />
            </div>
          )}
        </div>
        {!isCollapsed && isOpen && (
          <div className="submenu">
            {item.submenu.map((subItem) => (
              <MenuItem 
                key={subItem.id}
                item={subItem}
                isCollapsed={isCollapsed}
                activeLink={activeLink}
                setActiveLink={setActiveLink}
                level={level + 1}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <Link 
      to={item.path}
      className={`nav-item ${location.pathname === item.path ? 'active' : ''}`}
      onClick={() => setActiveLink(item.id)}
      style={{ paddingLeft: `${level * 12 + 16}px` }}
    >
      <div className="nav-item-icon">
        <item.icon className="nav-icon" />
      </div>
      {!isCollapsed && (
        <div className="nav-item-content">
          <span className="nav-label">{item.label}</span>
        </div>
      )}
    </Link>
  );
};

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeLink, setActiveLink] = useState('dashboard');

  const menuItems = [
    {
      category: "Main",
      items: [
        { icon: Layout, label: 'Dashboard', id: 'dashboard', path: '/dashboard' },
        {
          icon: Box,
          label: 'Product Management',
          id: 'product-management',
          submenu: [
            { icon: Layers, label: 'Products', id: 'products', path: '/pc' },
            { icon: Layers, label: 'Product-Categories', id: 'product-categories', path: '/product-categories' },
            { icon: FileText, label: 'Product Details', id: 'products-details', path: '/create-product-details' },
            { icon: Image, label: 'Product Gallery', id: 'products-gallery', path: '/productgallery' },
            { icon: Box, label: 'Accessories', id: 'accessories', path: '/accessories' },
            { icon: FileImage, label: 'Product Thumbnail', id: 'product-thumbnail', path: '/thumbnails' },
            { icon: Activity, label: 'Product Variants', id: 'product-variant', path: '/product-variants' },
            { icon: Table, label: 'Product Table', id: 'product-table', path: '/product-table' },
          ]
        },
        {
          icon: ShoppingBag,
          label: 'Accessory Management',
          id: 'accessory-management',
          submenu: [
            { icon: Box, label: 'Accessories', id: 'accessories', path: '/accessories' },
            { icon: FileText, label: 'Accessory Details', id: 'accessory-details', path: '/accessories-details' },
            { icon: ImagePlus, label: 'Accessory Thumbnail', id: 'accessory-thumbnail', path: '/accessory-thumbnails' },
            { icon: Table, label: 'Accessory Table', id: 'accessory-table', path: '/accessory-sizes' },
          ]
        },
        {
          icon: BookOpen,
          label: 'Content Management',
          id: 'content-management',
          submenu: [
            { icon: FileText, label: 'Brochure', id: 'brochure-details', path: '/brochure' },
            { icon: Image, label: 'Banner', id: 'banner', path: '/bannermanagement' },
            { icon: MessageSquare, label: 'Testimonial', id: 'testimonial', path: '/create-testimonials' },
          ]
        },
        { icon: Users, label: 'Customers', id: 'customers', path: '/contact-manage' },
        { icon: Users, label: 'Users', id: 'users', path: '/users' },
        { icon: Users, label: 'UsersTypes', id: 'usertypes', path: '/usertypes' },
        {
          icon: Globe,
          label: 'Location Management',
          id: 'location-management',
          submenu: [
            { icon: MapPin, label: 'Cities', id: 'city', path: '/cities' },
            { icon: Globe, label: 'Countries', id: 'country', path: '/countries' },
          ]
        },
      ]
    },
    {
      category: "Applications",
      items: [
        { icon: Calendar, label: 'Calendar', id: 'calendar', path: '/celender' },
        { icon: FileText, label: 'Documents', id: 'documents', path: '/documents' },
      ]
    },
    {
      category: "Settings",
      items: [
        { icon: Settings, label: 'Settings', id: 'settings', path: '/settings' },
      ]
    }
  ];

  return (
    <aside className={`technoflex-sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="sidebar-header">
        <span className="logo"></span>
        <button 
          className="collapse-btn" 
          onClick={() => setIsCollapsed(!isCollapsed)}
          title={isCollapsed ? "Expand" : "Collapse"}
        >
          {isCollapsed ? (
            <ChevronRight className="chevron-icon" />
          ) : (
            <ChevronLeft className={`chevron-icon ${isCollapsed ? 'rotate' : ''}`} />
          )}
        </button>
      </div>

      <nav className="sidebar-nav">
        {menuItems.map((category, index) => (
          <div key={index} className="nav-category">
            {!isCollapsed && <h3 className="category-title">{category.category}</h3>}
            {category.items.map((item) => (
              <MenuItem
                key={item.id}
                item={item}
                isCollapsed={isCollapsed}
                activeLink={activeLink}
                setActiveLink={setActiveLink}
              />
            ))}
          </div>
        ))}
      </nav>
    </aside>
  );
};

export default Sidebar;