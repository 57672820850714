import React, { lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


import Loader from "./components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import ScrollToTopButton from './components/Scrollup/ScrollToTopButton';
import ScrollToTop from './hooks/useWindowScrollToTop';
import ProtectedRoute from './components/ProtectedRoute';
import CalendarComponent from './components/CalendarComponent';
// Lazy loading components
const BannerManagement = lazy(() => import('./pages/ProductGallery/BannerManagement'));
const TestimonialManagement = lazy(() => import('./pages/ProductGallery/TestimonialManagement'));
const AccessoryThumbnails = lazy(() => import('./pages/ProductGallery/AccessoryThumbnails'));
const ProductThumbnails = lazy(() => import('./pages/ProductGallery/ProductThumbnails'));
const AccessorySizes = lazy(() => import('./pages/ProductGallery/AccessorySizes'));
const Brochures = lazy(() => import('./pages/Brochures/Brochures'));
const Login = lazy(() => import("./pages/authentication/Login"));
const PageNotFound = lazy(() => import("./pages/CommonPages/NotFound"));
const CreateProduct = lazy(() => import('./pages/productcreate/CreateProduct'));
const ProductTablePage = lazy(() => import('./components/Table/ProductTablePage'));
const ProductGallery = lazy(() => import('./pages/ProductGallery/ProductGallery'));
const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
const ProductAccessories = lazy(() => import('./pages/ProductGallery/ProductAccessories'));
const CreateProductDetails = lazy(() => import('./pages/productcreate/CreateProductDetails'));
const ProductVariants = lazy(() => import('./pages/ProductGallery/ProductVariants'));
const ContactUsManage = lazy(() => import('./pages/ContactUs/ContactUsManage'));
const ProductAccessoryDetails = lazy(() => import('./pages/ProductGallery/ProductAccessoryDetails'));
const Users = lazy(() => import('./pages/ProductGallery/Usermanagement'));
const UserTypes = lazy(() => import('./pages/ProductGallery/UserTypes'));
const ProductCategory = lazy(() => import('./pages/ProductGallery/ProductCategories'));
// Lazy loading for Cities and Countries components
const Cities = lazy(() => import('./pages/ProductGallery/CitiesManagement'));  // Make sure the path is correct
const Countries = lazy(() => import('./pages/ProductGallery/CountryManagement'));  // Ensure the path is correct

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
  
        <ScrollToTop />
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Login />} />
          <Route path="/pagenotfound" element={<PageNotFound />} />

          {/* Protected Routes */}
          <Route path="/create-testimonials" element={
            <ProtectedRoute><TestimonialManagement /></ProtectedRoute>
          } />
          <Route path="/pc" element={
            <ProtectedRoute><CreateProduct /></ProtectedRoute>
          } />
          <Route path="/brochure" element={
            <ProtectedRoute><Brochures /></ProtectedRoute>
          } />
          <Route path="/create-product-details" element={
            <ProtectedRoute><CreateProductDetails /></ProtectedRoute>
          } />
          <Route path="/productgallery" element={
            <ProtectedRoute><ProductGallery /></ProtectedRoute>
          } />
          <Route path="/product-table" element={
            <ProtectedRoute><ProductTablePage /></ProtectedRoute>
          } />
          <Route path="/product-variants" element={
            <ProtectedRoute><ProductVariants /></ProtectedRoute>
          } />
            <Route path="/product-categories" element={
            <ProtectedRoute><ProductCategory/></ProtectedRoute>
          } />

<Route path="/celender" element={
            <ProtectedRoute><CalendarComponent /></ProtectedRoute>
          } />
          <Route path="/accessories" element={
            <ProtectedRoute><ProductAccessories /></ProtectedRoute>
          } />
          <Route path="/contact-manage" element={
            <ProtectedRoute><ContactUsManage /></ProtectedRoute>
          } />
          <Route path="/bannermanagement" element={
            <ProtectedRoute><BannerManagement /></ProtectedRoute>
          } />
          <Route path="/accessories-details" element={
            <ProtectedRoute><ProductAccessoryDetails /></ProtectedRoute>
          } />
          <Route path="/accessory-sizes" element={
            <ProtectedRoute><AccessorySizes /></ProtectedRoute>
          } />
          <Route path="/thumbnails" element={
            <ProtectedRoute><ProductThumbnails /></ProtectedRoute>
          } />
          <Route path="/accessory-thumbnails" element={
            <ProtectedRoute><AccessoryThumbnails /></ProtectedRoute>
          } />

          {/* New Protected Routes for Cities and Countries */}
          <Route path="/cities" element={
            <ProtectedRoute><Cities /></ProtectedRoute>
          } />
          <Route path="/countries" element={
            <ProtectedRoute><Countries /></ProtectedRoute>
          } />

<Route path="/users" element={
            <ProtectedRoute><Users /></ProtectedRoute>
          } />

          
<Route path="/usertypes" element={
            <ProtectedRoute><UserTypes /></ProtectedRoute>
          } />

          {/* Dashboard as a Protected Route */}
          <Route path="/dashboard" element={
            <ProtectedRoute><Dashboard /></ProtectedRoute>
          } />
        </Routes>
        <ScrollToTopButton />

      </Router>
    </Suspense>
  );
}

export default App;
