import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  // You can check if the user is authenticated here.
  const isAuthenticated = localStorage.getItem('isAuthenticated'); // Simple check using localStorage

  if (!isAuthenticated) {
    // Redirect to login page if not authenticated
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
